<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex mb-4 align-items-center">
          <div class="title mr-auto">
            <h4 class="title">{{ labelPage }}</h4>
          </div>
          <form class="search mr-3" @submit.prevent="onSearch">
            <input
              v-model="search"
              type="text"
              @keyup="searchCheck"
              id="searchbox"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form>
          <router-link
            :to="{
              name: $route.name == 'TransferBank' ? 'InsertTransferBank' : 'InsertGeneralJournal'
            }"
            class="btn-add"
            v-if="
              checkAccess('kas_bank') || (labelPage == 'Jurnal Umum' && checkAccess('kas_bank', 'cru_jurnal_general')) ||
              (labelPage == 'Transfer Kas/Bank' && checkAccess('kas_bank', 'cru_transfer_kasBank'))
            "
            >+ Tambah
          </router-link>
        </div>
        <div class="card-table">
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="tableGeneralJurnal"
            >
              <thead>
                <tr>
                  <th style="width: 20%">Nomor Transaksi</th>
                  <th style="width: 15%">Tanggal</th>
                  <th style="width: 30%">Keterangan</th>
                  <th style="width: 20%">Tipe Transaksi</th>
                  <th style="width: 20%">Total</th>
                  <th style="width: 10%" class="text-center">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in listData" :key="index" :style="{
                  color: value.status == 'waiting' ? 'red' : ''
                }">
                  <td>{{ value.sumber }}</td>
                  <td>{{ convertDate(value.tanggal) }}</td>
                  <td>{{ value.keterangan ? value.keterangan : "-" }}</td>
                  <td>
                    {{ value.tipe_transaksi }}
                    <!-- <div class="green-bedge" v-if="value.status == 'aktif'">
                      Aktif
                    </div>
                    <div
                      class="orange-bedge text-capitalize"
                      v-else-if="value.status == 'waiting'"
                    >
                    Menunggu Persetujuan
                    </div>
                    <div class="red-bedge text-capitalize" v-else>Nonaktif</div> -->
                  </td>
                  <td class="text-right">
                    {{ value.total ? formatMoney(value.total) : 0 }}
                  </td>
                  <td class="text-center">
                    <svg
                      v-on:click="detail(value)"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Lihat Detail"
                      class="edit-icon"
                      width="27"
                      height="27"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.74935 2.58203V1.29036V2.58203ZM5.16602 5.16536H3.87435H5.16602ZM5.16602 25.832H3.87435H5.16602ZM18.0827 1.29036H7.74935V3.8737H18.0827V1.29036ZM7.74935 1.29036C6.72164 1.29036 5.73601 1.69862 5.00931 2.42533L6.836 4.25202C7.07824 4.00978 7.40678 3.8737 7.74935 3.8737V1.29036ZM5.00931 2.42533C4.28261 3.15203 3.87435 4.13765 3.87435 5.16536H6.45768C6.45768 4.82279 6.59377 4.49425 6.836 4.25202L5.00931 2.42533ZM3.87435 5.16536V25.832H6.45768V5.16536H3.87435ZM3.87435 25.832C3.87435 26.8597 4.28261 27.8454 5.00931 28.5721L6.836 26.7454C6.59377 26.5031 6.45768 26.1746 6.45768 25.832H3.87435ZM5.00931 28.5721C5.73601 29.2988 6.72163 29.707 7.74935 29.707V27.1237C7.40678 27.1237 7.07824 26.9876 6.836 26.7454L5.00931 28.5721ZM7.74935 29.707H23.2493V27.1237H7.74935V29.707ZM23.2493 29.707C24.2771 29.707 25.2627 29.2988 25.9894 28.5721L24.1627 26.7454C23.9205 26.9876 23.5919 27.1237 23.2493 27.1237V29.707ZM25.9894 28.5721C26.7161 27.8454 27.1243 26.8597 27.1243 25.832H24.541C24.541 26.1746 24.4049 26.5031 24.1627 26.7454L25.9894 28.5721ZM27.1243 25.832V5.16537H24.541V25.832H27.1243ZM23.2493 1.29036H18.0827V3.8737H23.2493V1.29036ZM27.1243 5.16537C27.1243 3.02526 25.3895 1.29036 23.2493 1.29036V3.8737C23.9627 3.8737 24.541 4.452 24.541 5.16537H27.1243Z"
                        fill="#898989"
                      />
                      <path
                        d="M21.9583 15.5H15.5"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3327 15.5H9.04102"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.9583 21.957H15.5"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3327 21.957H9.04102"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.9583 9.04297H18.7292H15.5"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.3327 9.04297H9.68685H9.04102"
                        stroke="#898989"
                        stroke-width="2.58333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <table-lite :is-static-mode="true" :is-loading="table.isLoading" :columns="table.columns"
                    :rows="table.rows" :total="table.totalRecordCount" :sortable="table.sortable"
                    @is-finished="table.isLoading = false"></table-lite> -->
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
// import Select2 from 'vue3-select2-component';

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";
import { cksClient, checkModuleAccess } from "../../../helper";
import { get_JurnalUmumList } from "../../../actions/jurnal_umum";
import moment from "moment";
import "moment/locale/id";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    // Select2
    // TableLite
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      listData: [],
      id_company: cksClient().get("_account").id_company,
      search: "",
      dataTable: "",
      labelPage: this.$route.name == 'TransferBank' ? 'Transfer Kas/Bank' : 'Jurnal Umum',
      isSearch: false,
      jurnalUmum: [5, 29, 31, 32, 33, 34, 35, 36, 37, 38],
    };
  },

  created() {
    this.getList();
  },

  mounted() {
    this.tooltip();
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },

  methods: {
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    convertDate(string) {
      return moment(string).format("DD MMM YYYY");
    },
    searchCheck: function () {
      if (!this.search && this.isSearch) {
        this.isSearch = false;
        this.dataTable.search('').draw()
        // this.getList();
      }
    },
    async onSearch() {
      if (this.search || this.isSearch) {
        this.isSearch = true;
        this.dataTable.search(this.search).draw()
        // this.getList();
      }
      if (!this.search && this.isSearch) {
        this.isSearch = false;
      }
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },

    detail(data) {
      $('[data-toggle="tooltip"]').tooltip("hide");
      if (data.id_tipe == "1") {
        this.$router.push({
          name: 'DetailIncome',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.id_tipe == "2") {
        this.$router.push({
          name: 'DetailSpending',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.id_tipe == "4" || data.id_tipe == "27" || data.id_tipe == "30") {
        this.$router.push({
          name: 'DetailAsset',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.id_tipe == "6") {
        this.$router.push({
          name: 'DetailAccountPayable',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.id_tipe == "7") {
        this.$router.push({
          name: 'DetailCredit',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.id_tipe == "8") {
        this.$router.push({
          name: 'DetailAccountPayable',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.id_tipe == "9") {
        this.$router.push({
          name: 'DetailCredit',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.id_tipe == "11") {
        this.$router.push({
          name: 'DetailCredit',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.id_tipe == "13") {
        this.$router.push({
          name: data.tipeNilai == 'inventory' ?  'DetailPO' : 'DetailPOSupply',
          params: {
            kode: data.sumber,
          },
        });
      } else if (data.id_tipe == "14") {
        this.$router.push({
          name: data.tipeNilai == 'inventory' ? 'DetailReception' : 'DetailReceptionSupply',
          params: {
            kode: data.sumber,
          },
        });
      } else if (data.id_tipe == "15") {
        this.$router.push({
          name: data.tipeNilai == 'inventory' ? 'DetailOpname'  : 'DetailOpnameSupply',
          params: {
            kode: data.sumber,
          },
        });
      } else if (data.id_tipe == "16") {
        this.$router.push({
          name: 'DetailEmployeeCash',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.id_tipe == "17") {
        this.$router.push({
          name: 'DetailEmployeeCash',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.id_tipe == "18") {
        var nameRoute = 'DetailSalary';
        if (data.tipeNilai == 'bonus') {
          nameRoute = 'DetailBonus'
        } else if (data.tipeNilai == 'thr') {
          nameRoute = 'DetailTHR'
        } else if (data.tipeNilai == 'man power') {
          nameRoute = 'DetailManPower'
        }
        this.$router.push({
          name: nameRoute,
          params: {
            id: data.sumber,
          },
        });
      } else if (data.id_tipe == "21") {
        this.$router.push({
          name: 'DetailReimburse',
          params: {
            id: data.sumber,
          },
        });
      } else if (data.id_tipe == "22") {
        this.$router.push({
          name: 'DetailAssetCheck',
          params: {
            id: data.sumber,
          },
        });
      }else if (data.id_tipe == "23") {
        this.$router.push({
          name: data.tipeNilai == 'inventory' ? 'DetailInventorySpending' : 'DetailStockSpending',
          params: {
            id: data.sumber,
          },
        });
      }else if (data.id_tipe == "26" || data.id_tipe == '35') {
        this.$router.push({
          name: 'DetailPaymentOrder',
          params: {
            id_hutang: data.sumber,
          },
        });
      } else if (data.id_tipe == "28") {
        this.$router.push({
          name: 'EditOpex',
          params: {
            id_hutang: data.sumber,
          },
        });
      } else {
        this.$router.push({
          name: this.$route.name == 'TransferBank' ? "DetailTransferBank" : "DetailGeneralJournal",
          params: {
            id: data.sumber,
          },
        });
      }
    },
    getList() {
      this.$swal.showLoading();
      get_JurnalUmumList(
        {
          id_company: this.id_company,
          search: this.search,
          is_transfer: this.$route.name == 'TransferBank' ? 1 : 0
        },
        (res) => {
          this.listData = [];
          this.listData = res.list;
          this.$swal.close();
          $('[data-toggle="tooltip"]').tooltip("hide");
          if (this.dataTable) {
            this.dataTable.destroy();
            this.dataTable = ''
          }
          setTimeout(() => {
            this.dataTable = $("#tableGeneralJurnal").DataTable({
              info: false,
              language: {
                emptyTable: "Tidak ada data",
              },
              order: [],
            });
            this.tooltip();
          }, 1000);
        }, () => {
          this.$swal.close();
        }
      );
    },
  },
  watch: {
    $route(to) {
      if (['TransferBank', 'DetailTransferBank', 'InsertTransferBank', 'GeneralJournal', 'DetailGeneralJournal', 'InsertGeneralJournal'].indexOf(to.name) >= 0) {
        this.labelPage = to.name == 'TransferBank' ? 'Transfer Kas/Bank' : 'Jurnal Umum';
        setTimeout(() => {
          this.getList();        
        }, 500);
      }
    }
  }
};
</script>

<style scoped>
button.btn-add {
  border: none !important;
}

tbody tr td {
  word-wrap: anywhere;
}
</style>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>
